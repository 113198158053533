<script setup>
import SiteHeader from '@/components/widgets/site-header';
import PageLayout from '@/components/layouts/page-layout';

</script>

<template lang="html">
  <SiteHeader />
  <PageLayout>
    <template #mainContent>
      <div class="page-404">
        404 PAGE
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import '~quasar-variables';

</style>
